@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,600;0,900;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 900px) {
  ::-webkit-scrollbar {
    width: 0px !important;
    background: transparent !important; /* make scrollbar transparent */
    }
}



* {
  padding: 0;
  margin: 0;
  border: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
}
/* width */
*::-webkit-scrollbar {
  width: 5px;
}

/* Track */
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

*:focus {
  outline: none;
}

:root {
  --nove-blue: #1c93d8;
}
.flex {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
/* Typography root */
.MuiTypography-root,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  /* font-family: 'Lato', sans-serif; */
}
.MuiOutlinedInput-notchedOutline {
  border: 0px !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.MuiOutlinedInput-notchedOutline {
  border-radius: 6px !important;
  background: "white" !important;
}
.MuiOutlinedInput-input {
  color: rgba(0, 0, 0, 0.5) !important;
  background: "white" !important;
}
.MuiInputBase-input {
  background: "white" !important;
  border-radius: 6px !important;
}
.react-multi-carousel-dot button {
  border-color: transparent !important;
  background-color: #e0e4fb !important;
  width: 8px !important;
  height: 8px !important;
  margin-right: 20px !important;
}
.react-multi-carousel-dot--active button {
  background-color: #6e7ebc !important;
  width: 8px !important;
  height: 8px !important;
  margin-right: 20px !important;
}
img {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
}

.serviceCardPara {
  font-size: 14px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 2px;
}
#navmobileburger {
  color: white;
  border-radius: 50%;
  background: rgb(28, 147, 216);
  margin-top: 10px;
  padding: 8px;
}
.threeLines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.fourLines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  -webkit-box-orient: vertical;
}
.oneLines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
}


